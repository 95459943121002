<template>
  <div>
    <CaHeader
      :loading="loading"
      :heading="$route.params.customerId ? `Kunde bearbeiten` : `Neuer Kunde`"
      :column-layout="columnLayout"
      show-column-layout
      @columnLayout="columnLayout = $event"
    >
      <template #buttonSpace>
        <button
          type="button"
          class="btn btn-primary mr-2"
          @click="save()"
        >
          Speichern <i
            v-if="safePending"
            class="fas fa-circle-notch fa-spin"
          />
        </button>
      </template>
      <template #alert>
        <ca-alert
          v-if="saveErrorMessage"
          variant="danger"
          :message="saveErrorMessage"
          @close="saveErrorMessage = null"
        />
        <ca-alert
          v-if="showFormInvalidHint"
          variant="info"
          message="Es sind noch nicht alle erforderlichen Felder ausgefüllt"
          @close="showFormInvalidHint = null"
        />
      </template>
    </CaHeader>
    <div class="container-fluid px-4 pt-4">
      <div class="row">
        <div
          class="col-12"
          :class="{'col-xl-8 col-xxl-6': !columnLayout}"
        >
          <div class="row">
            <div
              class="col-12"
              :class="{'col-lg-6': columnLayout}"
            >
              <CustomerForm
                ref="customerForm"
                :customer="customer"
                :is-new-customer="isNewCustomer"
              />
            </div>
            <div
              class="col-12"
              :class="{'col-lg-6': columnLayout}"
            >
              <ContactForm
                :customer="customer"
              />
            </div>
          </div>
          <div
            class="form-row justify-content-end"
          >
            <button
              class="btn btn-primary mr-2"
              @click="save()"
            >
              Speichern <i
                v-if="safePending"
                class="fas fa-circle-notch fa-spin"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header.vue'
import CustomerForm from '@/components/Forms/Customer/CustomerForm.vue'
import ContactForm from '@/components/Forms/Customer/ContactForm.vue'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    CaHeader,
    CustomerForm,
    ContactForm
  },
  data () {
    return {
      columnLayout: false,
      safePending: false,
      saveSuccess: false,
      saveErrorMessage: null,
      showFormInvalidHint: null,
      customer: {},
      loading: false
    }
  },
  computed: {
    isNewCustomer () {
      return !this.$route.params.customerId
    }
  },
  created () {
    if (this.$route.params.customerId) {
      this.fetchData()
    } else {
      this.customer = new this.$FeathersVuex.api.Customer()
      if (!this.customer.misc) this.$set(this.customer, 'misc', {})
    }
  },
  methods: {
    async fetchData () {
      try {
        this.loading = true
        this.customer = await this.$store.dispatch('customer/get', this.$route.params.customerId)
        this.$refs.customerForm.country = await this.$store.dispatch('countries/get', this.customer.address.NAT)
        if (!this.customer.misc) this.$set(this.customer, 'misc', {})
        if (this.customer.misc.VERSTORBAM) {
          this.$refs.customerForm.isDead = true
        }
      } catch (e) {
        console.error(e)
      } finally {
        if (this.customer) {
          this.loading = false
        }
      }
    },
    async save () {
      try {
        // Check if Form is Valid and not saving
        const valid = await this.$validator.validateAll()
        if (this.savePending) {
          return
        } else if (!valid) {
          this.showFormInvalidHint = true
          return
        }
        // Prepare for saving
        this.savePending = true
        this.saveErrorMessage = null
        this.showFormInvalidHint = false
        this.saveSuccess = false
        // Saving
        await this.customer.save()
        this.saveSuccess = true
      } catch (e) {
        console.error(e)
        this.saveErrorMessage = e.message
      } finally {
        this.savePending = false
        if (this.saveSuccess) {
          this.$router.push({ path: '/customer/list' })
        }
      }
    }
  }
}
</script>
